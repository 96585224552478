import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://eca6d53d4cc80ada75b52f54287930c6@o4508966971965440.ingest.us.sentry.io/4509024128794624",
  release: "",
  environment: "production",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
});
